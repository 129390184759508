import Vue from 'vue'
import App from './App.vue'
import 'lib-flexible/flexible.js'
import router from './router/router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "swiper/css/swiper.css";
import request from '../public/request'
Vue.use(ElementUI);
Vue.prototype.$request = request
new Vue({
  render: h => h(App),router
}).$mount('#app')
