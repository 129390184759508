import Vue from 'vue'
import Router from 'vue-router'

import VueRouter from "vue-router";
Vue.use(Router)
const routes =[
    {
        path:'/',component:()=>import('../view/home.vue')
    },
    {
        path:'/detaile',component:()=>import('../view/CardDetaile')
    },

]
const router = new VueRouter({
    routes
})
export default router